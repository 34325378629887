import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { IEquipment } from "app/pages/sofa/sofa.interface";

@Injectable({
  providedIn: "root",
})
export class EquipmentItemService {
  constructor(private http: HttpClient) {}

  deleteById(equipmentID: number): Observable<any> {
    return this.http.delete<any>(
      `${environment.apiEndpoint}/equipment/${equipmentID}`
    );
  }
}