// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.okta-button {
  border-color: #0066b2;
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/login/login.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,uBAAA;AACF","sourcesContent":[".okta-button {\n  border-color: #0066b2;\n  background-color: white;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
