import { Component, OnInit } from '@angular/core';
import { IReport } from 'app/helpers/interfaces/report.interface';
import { ReportService } from 'app/services/api/report.service';
import { LoadingIndicatorService } from 'app/services/shared';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-power-bi',
  templateUrl: './power-bi.component.html',
})
export class PowerBiComponent implements OnInit {

  reports: IReport[] = [];
  selectedReport: IReport | null = null;
  safeSrc: SafeResourceUrl | null = null;

  constructor(
    private readonly _reportService: ReportService,
    private readonly _loadingIndicatorSvc: LoadingIndicatorService,
    private readonly _sanitizer: DomSanitizer
  ){}

  ngOnInit(): void {
    this._fetchReports();
  }

  private _fetchReports(){
    this._loadingIndicatorSvc.show();
    this._reportService.getList()
    .pipe(
      finalize(()=>this._loadingIndicatorSvc.hide())
    )
    .subscribe(reports => {
      this.reports = reports;
    });
  }

  onReportSelect(report: IReport): void {
    this.selectedReport = report;
    this.safeSrc = this._sanitizer.bypassSecurityTrustResourceUrl(
      `https://app.powerbi.com/reportEmbed?reportId=${report.ReportID}&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26`
    );
  }
}
