import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ScreenModes } from 'app/helpers/enums/screenModes.enum';
import { IReport } from 'app/helpers/interfaces/report.interface';
import { ReportService } from 'app/services/api/report.service';
import { LoadingIndicatorService } from 'app/services/shared';
import { MessageService } from 'primeng/api';
import { catchError, EMPTY, finalize } from 'rxjs';

@Component({
  selector: 'app-reports-table',
  templateUrl: './reports-table.component.html',
})
export class ReportsTableComponent implements OnInit {

  ScreenModes: typeof ScreenModes = ScreenModes;

  reports: IReport[] = [];
  dialogVisibility: boolean = false;
  mode: ScreenModes = ScreenModes.NEW;

  reportForm: FormGroup = new FormGroup({
    ID: new FormControl<number>(0),
    Name: new FormControl<string>("", Validators.required),
    ReportID: new FormControl<string>("", Validators.required),
  })

  constructor(
    private readonly _reportService: ReportService,
    private readonly _loadingIndicatorSvc: LoadingIndicatorService,
    private readonly _messageService: MessageService,
    private readonly _translateService: TranslateService,
  ){}

  get header(): string{
    return this.mode === ScreenModes.NEW
      ? this._translateService.instant('pages.powerbi.add')
      : this._translateService.instant('pages.powerbi.edit');
  }

  ngOnInit(): void {
    this._fetchReports();
  }

  private _fetchReports(){
    this._loadingIndicatorSvc.show();
    this._reportService.getList()
    .pipe(
      finalize(()=>this._loadingIndicatorSvc.hide())
    )
    .subscribe(reports => {
      this.reports = reports;
    });
  }

  showDialog(mode: ScreenModes, report: IReport): void {
    this._resetForm();
    this.mode = mode;
    this.dialogVisibility = true;
    if(report){
      const {IsDisabled, ...reportToEdit} = report;
      this.reportForm.setValue(reportToEdit);
    }
  }

  private _saveReport(report: IReport){
    this._loadingIndicatorSvc.show();
    const method = this.mode === ScreenModes.NEW ? "post" : "put";

    this._reportService[method](report).pipe(
      finalize(()=>{
        this.closeDialog();
        this._loadingIndicatorSvc.hide()
      }),
      catchError(()=>{
        this._messageService.add({
          severity: "error",
          summary: this._translateService.instant("common.error"),
          detail: this.mode === ScreenModes.NEW ? "Error adding report" : "Error updating report",
          life: 5000,
        });
        return EMPTY;
      })
    )
    .subscribe({
      next: (newReport)=>{
        this._messageService.add({
          severity: "success",
          summary: this._translateService.instant("common.success"),
          detail: this.mode === ScreenModes.NEW ? "Report added successfully" : "Report updated successfully",
          life: 5000
        });

        this.mode === ScreenModes.NEW ? this.reports.push(newReport) : this.reports = this.reports.map(r=>r.ID === newReport.ID ? newReport : r);
      }
    });
  }

  onSubmit(): void{
    if(this.reportForm.invalid) return;
    const report: IReport = this.reportForm.value;
    this._saveReport(report);
  }

  closeDialog(): void {
    this.dialogVisibility = false;
    this._resetForm();
  }

  private _resetForm(){
    this.reportForm.reset({
      ID: 0,
      Name: "",
      ReportID: "",
    })
  }

  public onDelete(report: IReport){
    this._loadingIndicatorSvc.show();
    const reportToDelete = {
      ...report,
      IsDisabled: true
    };
    this._reportService.put(reportToDelete)
    .pipe(
      finalize(()=>this._loadingIndicatorSvc.hide()),
      catchError(()=>{
        this._messageService.add({
          severity: "error",
          summary: this._translateService.instant("common.error"),
          detail: "Error deleting report",
          life: 5000,
        });
        return EMPTY;
      })
    )
    .subscribe({
      next: ()=>{
        this._messageService.add({
          severity: "success",
          summary: this._translateService.instant("common.success"),
          detail: "Report deleted successfully",
          life: 5000
        });
        this.reports = this.reports.filter(r=>r.ID !== report.ID);
      }
    })
  }
}
