import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IReport } from 'app/helpers/interfaces/report.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

  getList(): Observable<IReport[]>{
    return this.http.get<IReport[]>(`${environment.apiEndpoint}/powerbi/reports`);
  }

  post(report: IReport): Observable<IReport>{
    return this.http.post<IReport>(`${environment.apiEndpoint}/powerbi/reports`, report);
  }

  put(report: IReport): Observable<IReport>{
    return this.http.put<IReport>(`${environment.apiEndpoint}/powerbi/reports`, report);
  }
}
