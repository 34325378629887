import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "#environments/environment";
import { Injectable } from "@angular/core";
import { IProjectCode } from "app/pages/project-code/project-code.interface";
import { IProjectCodeElement } from "app/pages/project-code/project-code-element.interface";
import { IProjectCostAPIM } from "app/helpers/interfaces/project-cost-APIM";
import { IProjectCodeCostBySummary } from "app/pages/outage/outage-summary/outage-tecode/outage-tecode.interface";

@Injectable({
  providedIn: "root",
})
export class ProjectCodeService {
  constructor(private http: HttpClient) {}

  getList({ cache = true } = {}): Observable<IProjectCode[]> {
    let url = `${environment.apiEndpoint}/project-code`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }

    return this.http.get<IProjectCode[]>(url, options);
  }

  getListProjectCodeElement(projectCodeId: number, cache = true ): Observable<IProjectCodeElement[]> {
    let url = `${environment.apiEndpoint}/element/${projectCodeId}`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }

    return this.http.get<IProjectCodeElement[]>(url, options);
  }


  putProjectCodeElement(payload: IProjectCodeElement, ID: Number): Observable<IProjectCodeElement> {
    return this.http.put<IProjectCodeElement>(
      `${environment.apiEndpoint}/element/${ID}`,
      payload
    );
  }

  getById(projectCodeId: number): Observable<IProjectCode> {
    return this.http.get<IProjectCode>(
      `${environment.apiEndpoint}/project-code/${projectCodeId}`
    );
  }

  post(payload: IProjectCode): Observable<IProjectCode> {
    return this.http.post<IProjectCode>(
      `${environment.apiEndpoint}/project-code`,
      payload
    );
  }

  postMultiple(payload: any, outageId: number): Observable<IProjectCode[]> {
    return this.http.post<any>(
      `${environment.apiEndpoint}/project-code/create-multiple/${outageId}`,
      payload
    );
  }

    getByNames(projectControlNames: string[]): Observable<IProjectCode[]> {
      return this.http.post<IProjectCode[]>(
        `${environment.apiEndpoint}/project-code/get-by-names`,
        projectControlNames
      );
    }

  put(payload: IProjectCode): Observable<IProjectCode> {
    return this.http.put<IProjectCode>(
      `${environment.apiEndpoint}/project-code/`,
      payload
    );
  }

  getProjectCode(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiEndpoint}/project-code`);
  }

  getEnabledProjectCode(): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.apiEndpoint}/project-code/getEnabled`
    );
  }

  getByOutage(outageID: number, cache = true): Observable<IProjectCode[]> {
    let url = `${environment.apiEndpoint}/project-code/get-by-outage/${outageID}`;
    let options = {};

    if (!cache) {
      options = {
        headers: new HttpHeaders({
          "Cache-control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        }),
      };
    }
    return this.http.get<IProjectCode[]>(url, options);
  }

  createFromAPIM(payload: IProjectCostAPIM, outageID: number): Observable<IProjectCodeCostBySummary>{
    const url: string = `${environment.apiEndpoint}/project-code/from-apim/${outageID}`;
    return this.http.post<IProjectCodeCostBySummary>(url, payload);
  }

}
