import { Inject, Injectable } from "@angular/core";
import { LoadingIndicatorService } from "./loading-indicator.service";
import { LoggedUser } from "app/models";
import { BehaviorSubject, EMPTY, Observable, Subscription } from "rxjs";
import { IUserRole } from "app/helpers/interfaces/user/user-role.interface";
import { catchError, concatMap, finalize, map } from "rxjs/operators";
import {
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
} from "@azure/msal-angular";
import { AuthenticationResult, RedirectRequest } from "@azure/msal-browser";
import { environment } from "#environments/environment";
import { UserService } from "../api/user.service";
import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AuthService {

  private isAdminSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isAdmin$: Observable<boolean> = this.isAdminSubject.asObservable();

  private readonly _httpWithoutInterceptor: HttpClient;

  constructor(
    private readonly _loadingIndicatorSvc: LoadingIndicatorService,
    private readonly _userService: UserService,
    private readonly _msalService: MsalService,
    @Inject(MSAL_GUARD_CONFIG)
    private readonly _msalGuardConfig: MsalGuardConfiguration,
    private readonly handler: HttpBackend,
  ) {
    this.isAdminSubject.next(this.isSystemAdmin());
    this._httpWithoutInterceptor = new HttpClient(this.handler);
  }

  getToken(): string {
    return sessionStorage.getItem("Token") || null;
  }

  getAuthorizationHeader(): string {
    const token = this.getToken();
    return token ? `Bearer ${token}` : "";
  }

  getFullName(): string {
    return sessionStorage.getItem("FullName") || "";
  }

  isAuthenticated(): boolean {
    return this._msalService.instance.getAllAccounts()?.length > 0 || false;
  }

  isAuthorized(): boolean {
    return this._msalService.instance.getAllAccounts()?.length > 0 || false;
  }

  getCurrentUser(): any {
    //Including Role in Current User object
    const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    if (currentUser !== null && currentUser !== undefined)
      currentUser.Role = JSON.parse(sessionStorage.getItem("Role"));
    return currentUser;
  }

  isSystemAdmin(): boolean {
    return sessionStorage.getItem("IsSystemAdmin")?.toLowerCase() === "true";
  }

  toggleIsSystemAdmin(): void {
    sessionStorage.setItem(
      "IsSystemAdmin",
      JSON.stringify(!this.isSystemAdmin())
    );
    this.isAdminSubject.next(this.isSystemAdmin());
  }

  IsUserDisabled(): boolean {
    return sessionStorage.getItem("IsDisabled")?.toLowerCase() === "true";
  }

  login(): void {
    if (this._msalGuardConfig.authRequest) {
      this._msalService.loginRedirect({
        ...this._msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this._msalService.loginRedirect();
    }
  }

  logout(): void {
    this._msalService.logoutRedirect();
    sessionStorage.clear();
  }

  acceptDisclaimer(userId: number) {
    this._loadingIndicatorSvc.show();
    this._userService
      .acceptDisclaimer(userId)
      .pipe(
        finalize(() => {
          this._loadingIndicatorSvc.hide();
        })
      )
      .subscribe(() => window.location.reload());
  }

  getUserData(): Observable<any> {
    sessionStorage.clear();
    this.getProfilePhoto();
    const account = this._msalService.instance.getActiveAccount();
    return this._msalService
      .acquireTokenSilent({
        scopes: environment.msalConfig.scopes,
        account: account,
      })
      .pipe(
        concatMap((tokenResponse: AuthenticationResult) => {
          sessionStorage.setItem("Token", tokenResponse.accessToken);
          return this._userService.getCurrentUser().pipe(
            concatMap((loggedUser: LoggedUser) => {
              sessionStorage.setItem("FullName", tokenResponse.account.name);
              sessionStorage.setItem("cai", loggedUser.CAI);
              sessionStorage.setItem(
                "bu",
                loggedUser.BusinessUnit?.Name || "Unknown"
              );
              sessionStorage.setItem(
                "IsSystemAdmin",
                JSON.stringify(loggedUser.IsSystemAdmin)
              );
              this.isAdminSubject.next(loggedUser.IsSystemAdmin);
              sessionStorage.setItem(
                "IsDisabled",
                JSON.stringify(loggedUser.IsDisabled)
              );

              sessionStorage.setItem("currentUser", JSON.stringify(loggedUser));
              return this._userService.getRoles(loggedUser.ID).pipe(
                map((roles: IUserRole[]) => {
                  sessionStorage.setItem("Role", JSON.stringify(roles));
                })
              );
            }),
          );
        })
      );
  }

  getRoles(): IUserRole[] {
    return JSON.parse(sessionStorage.getItem("Role")) || [];
  }

  private getProfilePhoto(): Subscription {
    const account = this._msalService.instance.getActiveAccount();
    return this._msalService
      .acquireTokenSilent({
        scopes: ['User.Read'],
        account: account,
      })
      .pipe(
        concatMap((response: AuthenticationResult) => {
          const headers: HttpHeaders = new HttpHeaders().set('Authorization', `Bearer ${response.accessToken}`);
          return this._httpWithoutInterceptor.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
            headers,
            responseType: 'blob'
          })
        }),
        catchError(error => {
          console.error('Error fetching profile image', error);
          return EMPTY;
        })
      )
      .subscribe((imageBlob: Blob) => {
        const reader:FileReader = new FileReader();
        reader.readAsDataURL(imageBlob);
        reader.onloadend = () => {
          const base64data: string = reader.result as string;
          sessionStorage.setItem("profileImgURL", base64data);
        }
      })
  }

  getProfileImageUrl(): string {
    return sessionStorage.getItem("profileImgURL") || null;
  }
}
