import { ChangeDetectorRef, Component, DoCheck, OnInit } from "@angular/core";
import { AuthService } from "../../services/shared";
import { LoggedUser } from "../../models/logged-user";
import { TranslateService } from "@ngx-translate/core";
import { MenuItem } from "primeng/api";

interface ILanguage {
  name: string;
  code: string;
}
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements DoCheck, OnInit {
  languages: ILanguage[];

  selectedLang: ILanguage;

  currentUser: LoggedUser;
  userRoles: Array<number> = [];
  isSysAdmin: boolean = false;

  items: MenuItem[] = [
    {
      label: "outage",
      routerLink: ["/outage"],
    },
    {
      label: "vendors",
      routerLink: ["/vendors"],
    },
    {
      label: "equipment class",
      routerLink: ["/equipment-class"],
    },
    {
      label: "crafts",
      routerLink: ["/crafts"],
    },
    {
      label: "power bi",
      routerLink: ["/power-bi"],
    }
  ];

  constructor(
    private translate: TranslateService,
    private readonly _authService: AuthService,
    private readonly _cdr: ChangeDetectorRef
  ) {
    this.languages = [
      {
        name: "https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg",
        code: "en",
      },
    ];
    this.selectedLang = this.languages[0];
  }

  ngOnInit(): void {
    const adminMenuItem: MenuItem = {
      label: "administration",
      items: [
        {
          label: "business units",
          routerLink: ["/admin/business-units"],
        },
        {
          label: "sites",
          routerLink: ["/admin/sites"],
        },
        {
          label: "users",
          routerLink: ["/admin/users"],
        },
        {
          label: "reports",
          routerLink: ["/admin/reports"],
        }
      ],
    }

    this._authService.isAdmin$.subscribe((isAdmin: boolean) => {
      if (isAdmin && !this.items.includes(adminMenuItem)) this.items = [...this.items, adminMenuItem];
      if (!isAdmin) this.items = this.items.filter(i => i.label !== "administration");
    });
  }

  ngDoCheck(): void {
    if (
      this.currentUser === undefined ||
      this.currentUser === null ||
      (this.userRoles.length === 0 && this.currentUser.IsSystemAdmin === false)
    )
      this.getCurrentUser();
  }

  onChange(event) {
    this.translate.use(event.value["code"]);
  }

  getTranslations(key: string) {
    this.translate.get(key).subscribe((translatedString) => {
      if (translatedString === undefined || translatedString == "") {
        return ""; // if value is getting undefined then return default language string, en_merch is default english language file here
      } else {
        return translatedString;
      }
    });
  }

  getCurrentUser(): void {
    this.currentUser = this._authService.getCurrentUser();
    this.userRoles = this._authService.getRoles()?.map((r) => r.Role);
    this.isSysAdmin = this._authService.isSystemAdmin();
    this._cdr.detectChanges();
  }

  getFullName(): string {
    return this._authService.getFullName();
  }

  get profileImgUrl():string{
    return this._authService.getProfileImageUrl();
  }
}
