import { NgModule } from "@angular/core";
import { Route, RouterModule } from "@angular/router";

import { PagesModule } from "app/pages/pages.module";
import { AdminPagesModule } from "app/pages/admin/admin.pages.module";
import { NotFoundComponent } from "app/pages/error/not-found/not-found.component";
import { ErrorPagesModule } from "app/pages/error/error.pages.module";
import { GenericErrorComponent } from "app/pages/error/generic-error/generic-error.component";
import { DBUnavailableComponent } from "app/pages/error/db-unavailable/db-unavailable.component";
import { NotAuthorizedComponent } from "app/pages/error/not-authorized/not-authorized.component";
import { IsApplicationUser, IsSystemAdmin } from "./app.routing.guards";
import { LoginComponent } from "app/pages/login/login.component";

import { UserRoleEnum } from "app/helpers/enums/userRole.enum";

import { TranslateModule } from "@ngx-translate/core";
import { BusinessUnitsComponent } from "./pages/admin/business-units/business-units.component";
import { PowerBiComponent } from "./pages/power-bi/power-bi.component";
import { ReportsTableComponent } from "./pages/admin/reports/reports-table/reports-table.component";

const routes: Route[] = [
  // Home page and slash-appender
  { path: "", redirectTo: "/login", pathMatch: "full" },
  // Pages
  {
    path: "",
    canActivate: [IsApplicationUser],
    children: [
      {
        path: "vendors",
        loadChildren: () =>
          import("./pages/vendors/vendors.module").then((m) => m.VendorsModule),
      },
      {
        path: "equipment-class",
        loadChildren: () =>
          import("./pages/equipment-class/equipment-class.module").then(
            (m) => m.EquipmentClassModule
          ),
      },
      {
        path: "project-code",
        loadChildren: () =>
          import("./pages/project-code/project-code.module").then(
            (m) => m.ProjectCodeModule
          ),
      },
      {
        path: "outage",
        loadChildren: () =>
          import("./pages/outage/outage.module").then((m) => m.OutageModule),
      },
      {
        path: "sofa",
        loadChildren: () =>
          import("./pages/sofa/sofa.module").then((m) => m.SofaModule),
      },
      {
        path: "crafts",
        loadChildren: () =>
          import("./pages/crafts/craft.module").then((m) => m.CraftModule),
      },
      {
        path: "power-bi",
        component: PowerBiComponent,
      
        loadChildren: () => import('./pages/power-bi/power-bi.module').then(m => m.PowerBiModule),
      }
    ],
  },

  // Admin pages
  {
    path: "admin",
    canActivate: [IsApplicationUser, IsSystemAdmin],
    children: [
      {
        path: "business-units",
        component: BusinessUnitsComponent,
        data: {
          roles: [
            UserRoleEnum.CostAnalyst,
            UserRoleEnum.Viewer,
            UserRoleEnum.ExternalBusinessPartner,
          ],
        },
      },
      {
        path: "sites",
        loadChildren: () =>
          import("./pages/admin/sites/sites.module").then((m) => m.SitesModule),
        data: {
          roles: [
            UserRoleEnum.CostAnalyst,
            UserRoleEnum.Viewer,
            UserRoleEnum.ExternalBusinessPartner,
          ],
        },
      },
      {
        path: "users",
        loadChildren: () =>
          import("./pages/users/users.module").then((m) => m.UsersModule),
        data: {
          roles: [],
        },
      },
      {
        path: "reports",
        component: ReportsTableComponent,
      },
    ],
  },

  // "Awaiting login" page
  {
    path: "login",
    component: LoginComponent,
  },

  // Error pages
  {
    path: "error",
    children: [
      { path: "not-found", component: NotFoundComponent },
      { path: "internal-error", component: GenericErrorComponent },
      { path: "not-authorized", component: NotAuthorizedComponent },
      { path: "db-unavailable", component: DBUnavailableComponent },
    ],
  },

  // Displays 404 "Not Found" error page for all unrecognized router.
  // Instead of redirecting, renders context to preserve original "incorrect" url
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {}),
    PagesModule,
    ErrorPagesModule,
    AdminPagesModule,
    TranslateModule,
  ],
  exports: [RouterModule, TranslateModule],
})
export class AppRoutingModule {}
