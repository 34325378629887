import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";

import { ApiModule } from "../../services/api/api.module";
import { LoadingIndicatorService } from "../../services/shared/loading-indicator.service";
import { SharedModule } from "../../services/shared/shared.module";
import { ConfirmationService } from "primeng/api";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";

import { AutoCompleteModule } from "primeng/autocomplete";
import { MultiSelectModule } from "primeng/multiselect";

import { ComponentsModule } from "app/components/components.module";
import { TableModule } from "primeng/table";
import { AccessControlModule } from "app/helpers/directives/access-control/access-control.module";

import { TranslateModule } from "@ngx-translate/core";
import { BusinessUnitsComponent } from './business-units/business-units.component';
import { ReportsTableComponent } from './reports/reports-table/reports-table.component';
@NgModule({
  declarations: [
    BusinessUnitsComponent,
    ReportsTableComponent,
  ],
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,

    // PrimeNG
    InputTextModule,
    ButtonModule,
    BrowserAnimationsModule,
    ConfirmDialogModule,
    DialogModule,
    AutoCompleteModule,
    MultiSelectModule,
    TableModule,

    // App
    ApiModule,
    SharedModule,
    FormsModule,
    ComponentsModule,
    AccessControlModule,

    //translate
    TranslateModule,
  ],
  providers: [LoadingIndicatorService, ConfirmationService],
})
export class AdminPagesModule {}
